@import "~@/styles/variables/variables.scss";
































































































































































































::v-deep {
	.info-title {
		display: flex;
		align-items: center;
		.status-self {
			margin-left: 18px;
			width: 64px;
			height: 20px;
			background: #e4f5ec;
			border-radius: 4px;
			display: flex !important;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			color: #16a65d;
			.dot {
				margin-right: 10px;
				width: 4px;
				height: 4px;
				background: #16a65d;
				border-radius: 50%;
			}
		}
	}
}

@import "~@/styles/variables/variables.scss";






























































































.tip {
	margin: 30px 0 0 24px;
	font-size: 12px;
	color: #ff9d57;
	line-height: 16px;
	// text-align: right;
}

@import "~@/styles/variables/variables.scss";













































































































































.split-line {
	width: 100%;
	border: 0.5px dashed #ccc;
	height: 0;
	transform: scaleY(0.5);
}
.pale-title {
	margin: 19px 0 20px;
	flex: 0 0 100%;
	font-size: 14px;
	color: #ababab;
	line-height: 14px;
}
::v-deep .el-form {
	margin-top: 19px;
	margin-left: 10px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.el-form-item {
		flex: 0 0 calc((100% - 30px) / 2);
		margin-right: 0;
		&:nth-child(2n),
		&.margin-right-30px {
			// margin-right: 30px;
		}
		&.original {
			margin-right: 0;
		}
		&.col-12 {
			flex: 0 0 100%;
			margin-right: 0;
		}
		.el-select {
			width: 100%;
		}
		.el-form-item__label {
			line-height: 14px;
			height: 14px;
			margin-bottom: 10px;
			font-weight: 600;
		}
	}
}

@import "~@/styles/variables/variables.scss";


























































































.operate {
	padding: 30px 24px 0 24px;
}

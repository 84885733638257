@import "~@/styles/variables/variables.scss";


















































































































































































.page-main-left {
	flex: 1;
	height: 100%;
	padding-bottom: 0;
	::v-deep {
		.grant {
			font-size: 14px;
			color: #4d6bff;
			line-height: 21px;
			cursor: pointer;
		}
		.gc-custom-search {
			width: 100% !important;
		}
		.gc-table {
			margin-top: 20px;
			height: calc(100% - 60px);
		}
	}
}
.page-main-right {
	flex: 0 0 420px;
	display: flex;
	flex-direction: column;
	.device-type-list {
		flex: 1;
		margin-top: 21px;
		// margin: 21px 0 24px;
		// overflow-y: scroll;
		@include base-scrollbar(-6px);
		overflow: auto;
		.device-type-item {
			margin-top: 20px;
			display: flex;
			align-items: flex-start;
			.list-style {
				font-size: 14px;
				font-weight: 600;
				color: #666666;
				line-height: 20px;
			}
			.left {
				flex: 1;
				.name {
					max-width: 246px;
					font-size: 14px;
					font-weight: 600;
					color: #666666;
					line-height: 18px;
					word-wrap: break-word;
					word-break: break-all;
				}
				.desc {
					max-width: 226px;
					margin-top: 10px;
					box-sizing: border-box;
					word-wrap: break-word;
					word-break: break-all;
					font-size: 12px;
					color: #666666;
					line-height: 14px;
				}
			}
			.icon-shanchu {
				color: #e64550;
				font-size: 14px;
				line-height: 18px;
			}
		}
	}
}
